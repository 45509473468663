import * as Yup from 'yup';

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { get, includes, isEmpty, isEqual, isNil, remove } from 'lodash/fp';
import {
  getComparator,
  handleChangePage,
  handleChangeRowsPerPage,
  handleClick,
  handleRequestSort,
  handleSelectAllClick,
  headCells,
  isSelected,
  stableSort
} from '../../components/FileManangerTable/utility';
import { postEntry, putStorage } from '../../redux/state/app/actions';

import AppSnackbar from '../../components/AppSnackbar';
import { AuthContext } from '../../context/AuthProvider/AuthProvider';
import Checkbox from '@material-ui/core/Checkbox';
import EntryForm from '../../components/EntryForm';
import FileLightBox from '../../components/FileManangerTable/FileLightBox';
import FileManagerTable from '../../components/FileManangerTable';
import Form from '../../components/Form';
import Image from 'react-image-resizer';
import Loading from '../../components/Loading';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core';
import { push } from 'connected-react-router';
import { v4 as uuidv4 } from 'uuid';

const propTypes = {
  auth: PropTypes.object,

  /** CSS styles generated by makeStyles that be overridden or extended */
  classes: PropTypes.object,

  storage: PropTypes.object
};

const useStyles = makeStyles({
  gallery: {
    marginTop: 50
  },
  imageGallery: {
    marginTop: 30
  },
  loaderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 40
  },
  root: props => props.root,
  tableCell: {
    minHeight: 300,
    width: '100%'
  }
});

const validationSchema = Yup.object({
  title: Yup.string('Enter entry title').required('Entry title is required'),
  date: Yup.date('Enter a date').required('Date is required'),
  age: Yup.string('Enter an age').required('Age is required')
});

const AddEntry = ({ auth, storage, ...props }) => {
  const formValues = { title: '', date: '', age: '' };
  const [editorContent, setEditorContent] = useState(null);
  const [editorError, setEditorError] = useState(false);
  const [loading, showLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [severity, setAlertSeverity] = useState('error');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('A message');
  const [uuid, setUUID] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('image');
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [openImageLightBox, setOpenImageLightBox] = useState(false);
  const classes = useStyles(props.classes);
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  const handleSubmit = useCallback(
    values => {
      const files = !isEmpty(rows)
        ? rows.map(row => ({
            file: {
              lastModified: get('file.lastModified', row),
              lastModifiedDate: get('file.lastModifiedDate', row),
              name: get('file.name', row),
              size: get('file.size', row),
              type: get('file.type', row),
              storageId: uuid
            }
          }))
        : [];
      const payload = {
        id: uuid,
        author: 'Brandon Jones',
        ...values,
        content: editorContent,
        files
      };

      showLoading(true);

      rows.map(row => dispatch(putStorage(uuid, row.file)));

      dispatch(postEntry(user, payload));

      setSelected([]);
      setRows([]);
      setSelectedRows([]);
    },
    [
      user,
      dispatch,
      editorContent,
      rows,
      showLoading,
      setRows,
      setSelectedRows,
      setSelected,
      uuid
    ]
  );

  const handleEditorChange = useCallback(() => {
    if (window.tinymce) {
      if (isEmpty(window.tinymce.activeEditor.getContent())) {
        setEditorError(true);
      } else {
        setEditorError(false);
        setEditorContent(window.tinymce.activeEditor.getContent());
      }
    }

    return null;
  }, [setEditorContent, setEditorError]);

  const closeAlert = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCancel = useCallback(() => {
    dispatch(push('/'));
  }, [dispatch]);

  const onFileUpload = useCallback(
    event => {
      if (isNil(event.target.files[0])) {
        return false;
      }

      const file = event.target.files[0];
      const fileType = file.type;

      if (!includes('video', fileType) && !includes('image', fileType)) {
        setImageLoading(false);
        setAlertSeverity('error');
        setOpen(true);
        setMessage('File upload failed. Unsupported file type');

        return false;
      }

      const newRow = {
        file,
        id: file.name,
        filePreview: URL.createObjectURL(file),
        image: !!includes('image', fileType),
        video: !!includes('video', fileType)
      };

      setImageLoading(true);

      setRows([...rows, newRow]);

      return setImageLoading(false);
    },
    [rows, setImageLoading, setRows]
  );

  const handleDelete = useCallback(() => {
    selectedRows.map(row => {
      setImageLoading(true);
      setRows(remove(row));
      setSelected([]);
      setSelectedRows([]);
      return setImageLoading(false);
    });
  }, [selectedRows, setImageLoading]);

  const onOpenImageLightBox = useCallback(() => {
    setOpenImageLightBox(true);
  }, [setOpenImageLightBox]);

  const closeImageLightBox = useCallback(() => {
    setOpenImageLightBox(false);
  }, [setOpenImageLightBox]);

  const isVideo = row => {
    return isEqual(true, get('video', row));
  };

  useEffect(() => {
    if (!uuid) {
      setUUID(uuidv4());
    }
  }, [uuid]);

  return loading ? (
    <div className={classes.loaderContainer}>
      <Loading size={150} />
    </div>
  ) : (
    <React.Fragment>
      <AppSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={closeAlert}
        key={{ vertical: 'top', horizontal: 'center' }}
        message={message}
        open={open}
        severity={severity}
        variant="filled"
      />
      <Form
        className={classes.root}
        component={EntryForm}
        editorFormFieldError={editorError}
        formValues={formValues}
        initialValues={formValues}
        onCancel={handleCancel}
        onEditorChange={handleEditorChange}
        onEditorMouseEnter={handleEditorChange}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      />
      <div className={classes.imageGallery}>
        <FileManagerTable
          className={classes.root}
          headCells={headCells}
          onChangePage={newPage => handleChangePage(newPage, setPage)}
          onChangeRowsPerPage={event =>
            handleChangeRowsPerPage(event, setPage, setRowsPerPage)
          }
          onDelete={handleDelete}
          onFileUpload={onFileUpload}
          onLightBoxOpen={onOpenImageLightBox}
          onSelectAllClick={event =>
            handleSelectAllClick(event, setSelected, setSelectedRows, rows)
          }
          onRequestSort={(event, property) =>
            handleRequestSort(
              event,
              order,
              orderBy,
              property,
              setOrder,
              setOrderBy
            )
          }
          order={order}
          orderBy={orderBy}
          page={page}
          rows={rows}
          rowsPerPage={rowsPerPage}
          selected={selected}
          title="Files"
        >
          {imageLoading ? (
            <TableRow>
              <TableCell className={classes.tableCell}>
                <div className={classes.loaderContainer}>
                  <Loading size={150} />
                </div>
              </TableCell>
            </TableRow>
          ) : (
            stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(selected, get('file', row));
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    id={row.id}
                    hover
                    onClick={event =>
                      handleClick(
                        event,
                        row.id,
                        get('file', row),
                        selected,
                        selectedRows,
                        setSelected,
                        setSelectedRows
                      )
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {isVideo(row) ? (
                        // eslint-disable-next-line jsx-a11y/media-has-caption
                        <video width="320" height="240" controls>
                          <source src={row.filePreview} />
                        </video>
                      ) : (
                        <Image src={row.filePreview} height={300} width={300} />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
          )}
        </FileManagerTable>
        <FileLightBox
          file={!isNil(selected[0]) ? selected[0] : null}
          onClose={closeImageLightBox}
          open={openImageLightBox}
        />
      </div>
    </React.Fragment>
  );
};

AddEntry.propTypes = propTypes;

function mapStateToProps(state) {
  return { auth: state.auth, storage: state.storage };
}

export default connect(mapStateToProps)(AddEntry);
