import axios from 'axios';
import { get } from 'lodash/fp';

export const GET_ENTRY = 'GET_ENTRY';
export const GET_ENTRY_ERROR = 'GET_ENTRY_ERROR';

export const getEntrySuccess = entry => ({
  type: GET_ENTRY,
  entry
});

export const getEntryError = error => ({
  type: GET_ENTRY_ERROR,
  error
});

export const getEntry = (token, id) => dispatch => {
  const config = { headers: { Authorization: `Bearer ${token}` } };

  axios
    .get(
      `https://us-central1-baby-book-app-7d00b.cloudfunctions.net/app/entries/${id}`,
      config
    )
    .then(response => {
      dispatch(getEntrySuccess(get('data', response)));
    })
    .catch(err => {
      dispatch(getEntryError(err));
    });
};
