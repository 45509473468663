import { assign } from 'lodash/fp';

export default (state, action) =>
  assign(state, {
    response: {
      loading: action.loading,
      alertSeverity: action.alertSeverity,
      open: action.open,
      message: action.message,
      user: action.user,
      type: 'add'
    }
  });
