import { Add, AspectRatio, Create, Delete } from '@material-ui/icons';
import { lighten, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const propTypes = {
  children: PropTypes.string,

  /** CSS styles generated by withStyles that be overridden or extended. */
  classes: PropTypes.object,

  onAdd: PropTypes.func,

  onEdit: PropTypes.func,

  onEntryPreview: PropTypes.func,

  onDelete: PropTypes.func,

  numSelected: PropTypes.number
};

const styles = theme => ({
  actions: {
    display: 'flex'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: '1 1 100%'
  }
});

const EntriesTableToolbar = ({
  children,
  classes,
  onAdd,
  onEdit,
  onEntryPreview,
  onDelete,
  numSelected
}) => {
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {children}
        </Typography>
      )}

      {numSelected > 0 ? (
        <div className={classes.actions}>
          <Tooltip title="Preview Entry">
            <span>
              <IconButton
                color="secondary"
                disabled={numSelected > 1}
                onClick={onEntryPreview}
              >
                <AspectRatio />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Edit Entry">
            <span>
              <IconButton
                color="secondary"
                disabled={numSelected > 1}
                onClick={onEdit}
              >
                <Create />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Delete Entry">
            <IconButton color="secondary" onClick={onDelete}>
              <Delete />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onAdd}
          startIcon={<Add />}
        >
          Add
        </Button>
      )}
    </Toolbar>
  );
};

EntriesTableToolbar.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(EntriesTableToolbar);
