import { DELETE_ENTRY, DELETE_ENTRY_ERROR } from './actions/deleteEntry';
import { GET_ENTRIES, GET_ENTRIES_ERROR } from './actions/getEntries';
import { GET_ENTRY, GET_ENTRY_ERROR } from './actions/getEntry';
import { POST_ENTRY, POST_ENTRY_ERROR } from './actions/postEntry';
import { PUT_ENTRY, PUT_ENTRY_ERROR } from './actions/putEntry';

import deleteEntryErrorReducer from './reducers/deleteEntryErrorReducer';
import deleteEntryReducer from './reducers/deleteEntryReducer';
import getEntriesErrorReducer from './reducers/getEntriesErrorReducer';
import getEntriesReducer from './reducers/getEntriesReducer';
import getEntryErrorReducer from './reducers/getEntryErrorReducer';
import getEntryReducer from './reducers/getEntryReducer';
import postEntryErrorReducer from './reducers/postEntryErrorReducer';
import postEntryReducer from './reducers/postEntryReducer';
import putEntryErrorReducer from './reducers/putEntryErrorReducer';
import putEntryReducer from './reducers/putEntryReducer';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case DELETE_ENTRY:
      return deleteEntryReducer(state, action);
    case DELETE_ENTRY_ERROR:
      return deleteEntryErrorReducer(state, action);
    case GET_ENTRIES:
      return getEntriesReducer(state, action);
    case GET_ENTRIES_ERROR:
      return getEntriesErrorReducer(state, action);
    case GET_ENTRY:
      return getEntryReducer(state, action);
    case GET_ENTRY_ERROR:
      return getEntryErrorReducer(state, action);
    case POST_ENTRY:
      return postEntryReducer(state, action);
    case POST_ENTRY_ERROR:
      return postEntryErrorReducer(state, action);
    case PUT_ENTRY:
      return putEntryReducer(state, action);
    case PUT_ENTRY_ERROR:
      return putEntryErrorReducer(state, action);
    default:
      return state;
  }
};
