import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';

const propTypes = {
  /** The anchor of the snackbar. */
  anchorOrigin: PropTypes.object,

  /** CSS styles generated by withStyles that be overridden or extended */
  classes: PropTypes.object,

  /** The number of milliseconds to wait before automatically calling the onClose function. onClose should then set the state of the open prop to hide the Snackbar. This behavior is disabled by default with the null value. */
  duration: PropTypes.number,

  /** When displaying multiple consecutive Snackbars from a parent rendering a single <Snackbar/>, add the key prop to ensure independent treatment of each message. e.g. <Snackbar key={message} />, otherwise, the message may update-in-place and features such as autoHideDuration may be canceled. */
  key: PropTypes.any,

  /** The snackbar message. */
  message: PropTypes.string,

  /** Callback fired when the component requests to be closed. Typically onClose is used to set state in the parent component, which is used to control the Snackbar open prop. The reason parameter can optionally be used to control the response to onClose, for example ignoring clickaway. */
  onClose: PropTypes.func,

  /** If true, Snackbar is open. */
  open: PropTypes.bool,

  /** The color of the Mui Alert. */
  severity: PropTypes.string,

  /** The variant to use. */
  variant: PropTypes.string
};

const styles = theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
});

const AppSnackbar = ({
  anchorOrigin,
  classes,
  duration,
  key,
  message,
  onClose,
  open,
  severity,
  variant
}) => (
  <div className={classes.root}>
    <Snackbar
      anchorOrigin={anchorOrigin}
      key={key}
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
    >
      <MuiAlert
        elevation={6}
        onClose={onClose}
        severity={severity}
        variant={variant}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  </div>
);

AppSnackbar.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(AppSnackbar);
