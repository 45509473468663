import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const propTypes = {
  /** CSS styles generated by withStyles that be overridden or extended */
  classes: PropTypes.object,

  /** The color of the component. It supports those theme colors that make sense for this component. */
  color: PropTypes.string,

  /** The size of the circle. If using a number, the pixel unit is assumed. If using a string, you need to provide the CSS unit, e.g '3rem'. */
  size: PropTypes.number
};

const styles = () => ({
  root: {}
});

const Loading = ({ classes, color, size }) => (
  <CircularProgress className={classes.root} color={color} size={size} />
);

Loading.propTypes = propTypes;

export default withStyles(styles)(Loading);
