import 'firebase/auth';

import firebase from 'firebase/app';
import { push } from 'connected-react-router';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SIGN_OUT = 'SIGN_OUT';

export const signIn = loggedIn => ({
  type: SIGN_IN,
  ...loggedIn
});

export const signInError = loggedInError => ({
  type: SIGN_IN_ERROR,
  ...loggedInError
});

export const signOut = loggedOut => ({
  type: SIGN_OUT,
  ...loggedOut
});

export const login = values => dispatch => {
  firebase
    .auth()
    .signInWithEmailAndPassword(values.userEmail, values.password)
    .then(() => {
      dispatch(
        signIn({
          passwordReset: false,
          formSubmission: true,
          alertSeverity: null,
          open: false,
          message: null
        })
      );
      dispatch(push('/'));
    })
    .catch(error => {
      dispatch(
        signInError({
          passwordReset: false,
          formSubmission: false,
          alertSeverity: 'error',
          open: true,
          message: error.message,
          code: error.code
        })
      );
    });
};

export const logOut = () => dispatch => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      dispatch(signOut({ anchorEl: null }));
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err, 'error signing out...'));
};
