export const DELETE_ALL_ENTRIES = 'DELETE_ALL_ENTRIES';

export const deleteAllEntriesSuccess = (entries, user) => ({
  type: DELETE_ALL_ENTRIES,
  entries,
  user
});

export const deleteAllEntries = (entries, user) => dispatch => {
  dispatch(deleteAllEntriesSuccess(entries, user));
};
