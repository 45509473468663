import 'firebase/storage';

import firebase from 'firebase/app';
import { get } from 'lodash/fp';

export const PUT_STORAGE = 'PUT_STORAGE';
export const PUT_STORAGE_ERROR = 'PUT_STORAGE_ERROR';

export const putStorageSuccess = success => ({
  type: PUT_STORAGE,
  ...success
});

export const putStorageError = error => ({
  type: PUT_STORAGE_ERROR,
  ...error
});

export const putStorage = (key, file) => dispatch => {
  const storageRef = firebase.storage().ref(`${key}/${file.name}`);

  storageRef
    .put(file)
    .then(snapshot => {
      const metadata = get('metadata', snapshot);

      dispatch(
        putStorageSuccess({
          contentType: get('contentType', metadata),
          fullPath: get('fullPath', metadata),
          name: get('name', metadata),
          loading: false,
          alertSeverity: 'success',
          open: true,
          message: 'Your file was uploaded successfully!'
        })
      );
    })
    .catch(err =>
      dispatch(
        putStorageError({
          loading: false,
          alertSeverity: 'error',
          open: true,
          message: err.message
        })
      )
    );
};
