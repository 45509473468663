import { assign } from 'lodash/fp';

export default (state, action) =>
  assign(state, {
    anchorEl: action.anchorEl,
    passwordReset: action.passwordReset,
    formSubmission: action.formSubmission,
    alertSeverity: action.alertSeverity,
    open: action.open,
    message: action.message,
    code: action.code
  });
