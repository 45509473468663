import { assign } from 'lodash/fp';

export default (state, action) =>
  assign(state, {
    file: {
      contentType: action.contentType,
      fullPath: action.fullPath,
      name: action.name
    },
    response: {
      loading: action.loading,
      alertSeverity: action.alertSeverity,
      open: action.open,
      message: action.message,
      type: 'put'
    }
  });
