import AppButton from '../AppButton';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const propTypes = {
  /** CSS styles generated by withStyles that be overridden or extended */
  classes: PropTypes.object,

  /** Formik errors. */
  errors: PropTypes.object,

  /** Callback for handling the onBlur events for the form fields. */
  handleBlur: PropTypes.func,

  /** Callback for handling onChange events for the form fields. */
  handleChange: PropTypes.func,

  /** Callback for handling the form submission. */
  handleSubmit: PropTypes.func,

  /** If true, the form is in a valid state. */
  isValid: PropTypes.bool,

  /** Callback when the forgot password flow is canceled. */
  onCancel: PropTypes.func,

  /** Object of form fields that have been visited. */
  touched: PropTypes.object,

  /** Object of form field values. */
  values: PropTypes.object
};

const styles = () => ({
  cancel: {
    marginRight: 'auto',
    marginTop: 15
  },
  paper: {
    padding: 25
  },
  root: {},
  submit: {
    marginLeft: 'auto',
    marginTop: 15
  },
  textField: {
    width: '100%'
  }
});

const ForgotPasswordForm = ({
  classes,
  errors,
  handleSubmit,
  handleBlur,
  handleChange,
  isValid,
  onCancel,
  touched,
  values: { forgotPasswordUserEmail }
}) => (
  <form onSubmit={handleSubmit}>
    <Grid container alignItems="center" justify="center">
      <Grid item xs={12} sm={7}>
        <Paper className={classes.paper}>
          <Typography variant="h4" component="h4">
            Reset Password
          </Typography>

          <TextField
            className={classes.textField}
            error={
              touched.forgotPasswordUserEmail &&
              Boolean(errors.forgotPasswordUserEmail)
            }
            helperText={
              touched.forgotPasswordUserEmail
                ? errors.forgotPasswordUserEmail
                : ''
            }
            id="forgotPasswordUserEmail"
            label="User Email"
            margin="normal"
            name="forgotPasswordUserEmail"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="user email"
            required
            type="text"
            value={forgotPasswordUserEmail}
            variant="outlined"
          />

          <Grid container item xs={12}>
            <Grid container item xs={6}>
              <AppButton
                classes={{ root: classes.cancel }}
                size="large"
                onClick={onCancel}
              >
                Cancel
              </AppButton>
            </Grid>
            <Grid container item xs={6}>
              <AppButton
                classes={{ root: classes.submit }}
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={!isValid}
              >
                Submit
              </AppButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </form>
);

ForgotPasswordForm.propTypes = propTypes;

export default withStyles(styles)(ForgotPasswordForm);
