import { assign, get } from 'lodash/fp';

export default (state, action) =>
  assign(state, {
    file: {
      contentType: get('contentType', action.storage[1]),
      name: get('name', action.storage[1]),
      url: action.storage[0]
    }
  });
