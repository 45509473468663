import * as Yup from 'yup';

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { get, includes, isEmpty, isEqual, isNil, remove } from 'lodash/fp';
import {
  getComparator,
  handleChangePage,
  handleChangeRowsPerPage,
  handleClick,
  handleRequestSort,
  handleSelectAllClick,
  headCells,
  isSelected,
  stableSort
} from '../../components/FileManangerTable/utility';
import {
  getEntry,
  getStorage,
  listStorage,
  putEntry,
  putStorage,
  removeStorage
} from '../../redux/state/app/actions';

import AppSnackbar from '../../components/AppSnackbar';
import { AuthContext } from '../../context/AuthProvider/AuthProvider';
import Checkbox from '@material-ui/core/Checkbox';
import EntryForm from '../../components/EntryForm';
import FileLightBox from '../../components/FileManangerTable/FileLightBox';
import FileManagerTable from '../../components/FileManangerTable';
import Form from '../../components/Form';
import Image from 'react-image-resizer';
import Loading from '../../components/Loading';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core';
import { push } from 'connected-react-router';

// import { v4 as uuidv4 } from 'uuid';

const propTypes = {
  auth: PropTypes.object,

  /** CSS styles generated by maketyles that be overridden or extended */
  classes: PropTypes.object,

  entries: PropTypes.object,

  match: PropTypes.object,

  storage: PropTypes.object
};

const useStyles = makeStyles({
  imageGallery: {
    marginTop: 30
  },
  loaderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 40
  },
  root: props => props.root,
  tableCell: {
    minHeight: 300,
    width: '100%'
  }
});

const validationSchema = Yup.object({
  title: Yup.string('Enter entry title').required('Entry title is required'),
  date: Yup.date('Enter a date').required('Date is required'),
  age: Yup.string('Enter an age').required('Age is required')
});

const EditEntry = ({ auth, entries, match, storage, ...props }) => {
  const formValues = {
    title:
      (!isNil(entries) && !isNil(entries.entry) && entries.entry.title) || '',
    date:
      (!isNil(entries) && !isNil(entries.entry) && entries.entry.date) || '',
    age: (!isNil(entries) && !isNil(entries.entry) && entries.entry.age) || '',
    content:
      (!isNil(entries) && !isNil(entries.entry) && entries.entry.content) || ''
  };

  const [editorContent, setEditorContent] = useState('');
  const [editorError, setEditorError] = useState(false);
  const [loading, showLoading] = useState(false);
  const [message, setMessage] = useState('A message');
  const [open, setOpen] = useState(false);
  const [entryPosted, setEntryPosted] = useState(false);
  const [severity, setAlertSeverity] = useState('error');
  const [uuid, setUUID] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('image');
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [markedForDelete, setMarkedForDelete] = useState([]);
  const [componentDidMount, setComponentDidMount] = useState(false);
  const [loadFiles, setLoadFiles] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [fileDeleted, setFileDeleted] = useState(false);
  const [openImageLightBox, setOpenImageLightBox] = useState(false);
  const classes = useStyles(props.classes);
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  const handleCancel = useCallback(() => {
    dispatch(push('/'));
  }, [dispatch]);

  const handleEditorChange = useCallback(() => {
    if (window.tinymce) {
      if (isEmpty(window.tinymce.activeEditor.getContent())) {
        setEditorError(true);
      } else {
        setEditorError(false);
        setEditorContent(window.tinymce.activeEditor.getContent());
      }
    }

    return null;
  }, [setEditorContent, setEditorError]);

  const handleSubmit = useCallback(
    values => {
      const files = !isEmpty(rows)
        ? rows.map(row => ({
            file: {
              lastModified: get('file.lastModified', row),
              lastModifiedDate: get('file.lastModifiedDate', row),
              name: get('file.name', row),
              size: get('file.size', row),
              type: get('file.type', row),
              storageId: uuid
            }
          }))
        : [];
      const payload = {
        author: 'Brandon Jones',
        ...values,
        content: editorContent,
        files
      };

      dispatch(putEntry(user, payload, uuid));

      showLoading(true);

      rows.map(
        row =>
          isEqual(false, row.inStorage) && dispatch(putStorage(uuid, row.file))
      );

      markedForDelete.map(row => dispatch(removeStorage(uuid, row.file)));

      setEntryPosted(true);
    },
    [
      dispatch,
      editorContent,
      markedForDelete,
      rows,
      showLoading,
      setEntryPosted,
      uuid,
      user
    ]
  );

  const closeAlert = useCallback(() => {
    setOpen(false);
  }, []);

  const onFileUpload = useCallback(
    event => {
      const file = event.target.files[0];
      const fileType = file.type;

      if (!includes('video', fileType) && !includes('image', fileType)) {
        setImageLoading(false);
        setAlertSeverity('error');
        setOpen(true);
        setMessage('File upload failed. Unsupported file type');

        return false;
      }

      const newRow = {
        file,
        id: file.name,
        filePreview: URL.createObjectURL(file),
        image: !!includes('image', fileType),
        video: !!includes('video', fileType),
        inStorage: false
      };

      setImageLoading(true);

      setRows([...rows, newRow]);

      return setImageUploaded(true);
    },
    [rows, setImageLoading, setRows]
  );

  const handleFileDelete = useCallback(() => {
    setImageLoading(true);
    selectedRows.map(
      row => row.inStorage && setMarkedForDelete([...markedForDelete, row])
    );
    setFileDeleted(true);
    return setOpen(true);
  }, [markedForDelete, setFileDeleted, setImageLoading, selectedRows]);

  const onOpenImageLightBox = useCallback(() => {
    setOpenImageLightBox(true);
  }, [setOpenImageLightBox]);

  const closeImageLightBox = useCallback(() => {
    setOpenImageLightBox(false);
  }, [setOpenImageLightBox]);

  const isVideo = row => {
    return isEqual(true, get('video', row));
  };

  useEffect(() => {
    const id = get('params.id', match);

    if (!isNil(user) && !isEqual(false, user)) {
      setUUID(id);
      dispatch(getEntry(get('Aa', user), id));
      dispatch(listStorage(id));
      setComponentDidMount(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (isEqual(true, entryPosted)) {
      dispatch(getEntry(get('Aa', user), match.params.id));
      showLoading(get('loading', entries.response));
      setAlertSeverity(get('alertSeverity', entries.response));
      setOpen(get('open', entries.response));
      setMessage(get('message', entries.response));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries.response, user]);

  useEffect(() => {
    if (isEqual(true, imageUploaded)) {
      setImageLoading(false);
      setAlertSeverity('success');
      setOpen(true);
      setImageUploaded(false);
      setMessage('Image was uploaded successfully');
    }
  }, [imageUploaded]);

  useEffect(() => {
    if (isEqual(true, fileDeleted)) {
      setImageLoading(false);
      setAlertSeverity('success');
      setOpen(true);
      setImageUploaded(false);
      setMessage('Image was deleted successfully');

      selectedRows.map(row => {
        setRows(remove(row));
        setSelected([]);
        return setSelectedRows([]);
      });

      setFileDeleted(false);
    }
  }, [fileDeleted, selectedRows, setOpen]);

  useEffect(() => {
    if (!isEmpty(storage) && get('list', storage) && componentDidMount) {
      setLoadFiles(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage.list]);

  useEffect(() => {
    if (isEqual(true, componentDidMount)) {
      storage.list.map(item => dispatch(getStorage(item)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadFiles]);

  useEffect(() => {
    const file = get('file', storage);

    if (file && loadFiles) {
      const newRow = {
        file,
        id: get('name', file),
        image: includes('image', get('contentType', file)),
        video: includes('video', get('contentType', file)),
        filePreview: get('url', file),
        inStorage: true
      };
      setRows([...rows, newRow]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage.file]);

  return loading ? (
    <div className={classes.loaderContainer}>
      <Loading size={150} />
    </div>
  ) : (
    <div className={classes.root}>
      <AppSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={closeAlert}
        key={{ vertical: 'top', horizontal: 'center' }}
        message={message}
        open={open}
        severity={severity}
        variant="filled"
      />
      <Form
        className={classes.root}
        component={EntryForm}
        edit
        editorFormFieldError={editorError}
        formValues={formValues}
        initialValues={formValues}
        onCancel={handleCancel}
        onEditorChange={handleEditorChange}
        onEditorMouseEnter={handleEditorChange}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      />
      <div className={classes.imageGallery}>
        <FileManagerTable
          className={classes.root}
          headCells={headCells}
          onChangePage={newPage => handleChangePage(newPage, setPage)}
          onChangeRowsPerPage={event =>
            handleChangeRowsPerPage(event, setPage, setRowsPerPage)
          }
          onDelete={handleFileDelete}
          onFileUpload={onFileUpload}
          onLightBoxOpen={onOpenImageLightBox}
          onSelectAllClick={event =>
            handleSelectAllClick(event, setSelected, setSelectedRows, rows)
          }
          onRequestSort={(event, property) =>
            handleRequestSort(
              event,
              order,
              orderBy,
              property,
              setOrder,
              setOrderBy
            )
          }
          order={order}
          orderBy={orderBy}
          page={page}
          rows={rows}
          rowsPerPage={rowsPerPage}
          selected={selected}
          title="Files"
        >
          {imageLoading ? (
            <TableRow>
              <TableCell className={classes.tableCell}>
                <div className={classes.loaderContainer}>
                  <Loading size={150} />
                </div>
              </TableCell>
            </TableRow>
          ) : (
            stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(selected, get('file', row));
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    id={row.id}
                    hover
                    onClick={event =>
                      handleClick(
                        event,
                        row.id,
                        get('file', row),
                        row.inStorage,
                        selected,
                        selectedRows,
                        setSelected,
                        setSelectedRows
                      )
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {isVideo(row) ? (
                        // eslint-disable-next-line jsx-a11y/media-has-caption
                        <video width="320" height="240" controls>
                          <source src={row.filePreview} />
                        </video>
                      ) : (
                        <Image src={row.filePreview} height={300} width={300} />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
          )}
        </FileManagerTable>
        <FileLightBox
          file={!isNil(selected[0]) ? selected[0] : null}
          onClose={closeImageLightBox}
          open={openImageLightBox}
        />
      </div>
    </div>
  );
};

EditEntry.propTypes = propTypes;

function mapStateToProps(state) {
  return { auth: state.auth, entries: state.entries, storage: state.storage };
}

export default connect(mapStateToProps)(EditEntry);
