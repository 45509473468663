import { AddPhotoAlternate, AspectRatio, Delete } from '@material-ui/icons';

import Button from '@material-ui/core/Button';
import FileUpload from '../../FileUpload';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { lighten } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';

const propTypes = {
  children: PropTypes.string,

  /** CSS styles generated by makeStyles that be overridden or extended. */
  classes: PropTypes.object,

  onDelete: PropTypes.func,

  onFileUpload: PropTypes.func,

  onLightBoxOpen: PropTypes.func,

  numSelected: PropTypes.number
};

const useSyles = makeStyles(theme => ({
  actions: {
    display: 'flex'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  root: props =>
    props.root
      ? props.root
      : {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(1)
        },
  title: {
    flex: '1 1 100%'
  }
}));

const FileManagerTableToolbar = ({
  children,
  onDelete,
  onFileUpload,
  onLightBoxOpen,
  numSelected,
  ...props
}) => {
  const classes = useSyles(props.classes);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {children}
        </Typography>
      )}

      {numSelected > 0 ? (
        <div className={classes.actions}>
          <Tooltip title="View Full Image">
            <span>
              <IconButton
                color="secondary"
                disabled={numSelected > 1}
                onClick={onLightBoxOpen}
              >
                <AspectRatio />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Delete Entry">
            <IconButton color="secondary" onClick={onDelete}>
              <Delete />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        <FileUpload id="file-upload" onChange={onFileUpload} type="file">
          <Button
            color="primary"
            component="span"
            startIcon={<AddPhotoAlternate />}
            variant="contained"
          >
            Add
          </Button>
        </FileUpload>
      )}
    </Toolbar>
  );
};

FileManagerTableToolbar.propTypes = propTypes;

export default FileManagerTableToolbar;
