import axios from 'axios';
import { get } from 'lodash/fp';

export const POST_ENTRY = 'POST_ENTRY';
export const POST_ENTRY_ERROR = 'POST_ENTRY_ERROR';

export const postEntrySuccess = success => ({
  type: POST_ENTRY,
  ...success
});

export const postEntryError = error => ({
  type: POST_ENTRY_ERROR,
  ...error
});

export const postEntry = (user, payload) => dispatch => {
  const token = get('Aa', user);
  const config = { headers: { Authorization: `Bearer ${token}` } };

  axios
    .post(
      'https://us-central1-baby-book-app-7d00b.cloudfunctions.net/app/entries',
      payload,
      config
    )
    .then(() => {
      dispatch(
        postEntrySuccess({
          loading: false,
          alertSeverity: 'success',
          open: true,
          message: 'Your entry was added successfully!',
          user
        })
      );
    })
    .catch(err => {
      dispatch(
        postEntryError({
          loading: false,
          alertSeverity: 'error',
          open: true,
          message: err.message
        })
      );
    });
};
