import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { isEqual } from 'lodash/fp';
import { withStyles } from '@material-ui/core/styles';

const propTypes = {
  /** Aria-controls text to display. */
  ariaControls: PropTypes.string,

  /** Aria-has-popup text to display. */
  ariaHasPopup: PropTypes.string,

  /** Aria-label text to display. */
  ariaLabel: PropTypes.string,

  /** CSS styles generated by withStyles that be overridden or extended. */
  classes: PropTypes.object,

  /** The contents of the button. */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),

  /** The color of the component. */
  color: PropTypes.string,

  component: PropTypes.string,

  /** If true, the button will be disabled. */
  disabled: PropTypes.bool,

  /** If given, uses a negative margin to counteract the padding on one side (this is often helpful for aligning the left or right side of the icon with content above or below, without ruining the border size and shape). */
  edge: PropTypes.string,

  icon: PropTypes.node,

  /** Which type of button mode to display. */
  mode: PropTypes.string,

  /** Callback fired when the component is clicked. */
  onClick: PropTypes.func,

  /** The size of the button. */
  size: PropTypes.string,

  /** The button type. */
  type: PropTypes.string,

  /** The variant to use. */
  variant: PropTypes.string
};

const styles = () => ({
  root: {}
});

const AppButton = ({
  ariaControls,
  ariaHasPopup,
  ariaLabel,
  classes,
  children,
  color,
  component,
  disabled,
  edge,
  icon,
  mode,
  onClick,
  size,
  type,
  variant
}) =>
  mode && isEqual('icon', mode) ? (
    <IconButton
      aria-controls={ariaControls}
      aria-haspopup={ariaHasPopup}
      aria-label={ariaLabel}
      className={classes.root}
      color={color}
      component={component}
      disabled={disabled}
      edge={edge}
      onClick={onClick}
      type={type}
      variant={variant}
    >
      {children}
    </IconButton>
  ) : (
    <Button
      aria-label={ariaLabel}
      className={classes.root}
      color={color}
      component={component}
      disabled={disabled}
      onClick={onClick}
      size={size}
      startIcon={icon}
      type={type}
      variant={variant}
    >
      {children}
    </Button>
  );

AppButton.propTypes = propTypes;

export default withStyles(styles)(AppButton);
