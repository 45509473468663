import 'firebase/storage';

import firebase from 'firebase/app';
import { get } from 'lodash/fp';

export const LIST_STORAGE = 'LIST_STORAGE';
export const LIST_STORAGE_ERROR = 'LIST_STORAGE_ERROR';

export const listStorageSuccess = storage => ({
  type: LIST_STORAGE,
  storage
});

export const listStorageError = error => ({
  type: LIST_STORAGE_ERROR,
  error
});

export const listStorage = key => dispatch => {
  const storageRef = firebase.storage().ref(key);

  storageRef
    .listAll()
    .then(result => dispatch(listStorageSuccess(get('items', result))))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log(err);
      dispatch(listStorageError(err));
    });
};
