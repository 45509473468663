export const GET_STORAGE = 'GET_STORAGE';
export const GET_STORAGE_ERROR = 'GET_STORAGE_ERROR';

export const getStorageSuccess = storage => ({
  type: GET_STORAGE,
  storage
});

export const getStorageError = error => ({
  type: GET_STORAGE_ERROR,
  error
});

export const getStorage = item => dispatch => {
  Promise.all([item.getDownloadURL(), item.getMetadata()])
    .then(response => {
      dispatch(getStorageSuccess(response));
    })
    .catch(err => {
      dispatch(getStorageError(err));
    });
};
