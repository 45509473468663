import FileManagerTableHeader from './FileManagerTableHeader';
import FileManagerTableToolbar from './FileManagerTableToolbar';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core';

const propTypes = {
  children: PropTypes.node,

  /** CSS styles generated by makeStyles that be overridden or extended */
  classes: PropTypes.object,

  headCells: PropTypes.array,

  onFileUpload: PropTypes.func,

  onLightBoxOpen: PropTypes.func,

  onChangePage: PropTypes.func,

  onChangeRowsPerPage: PropTypes.func,

  onDelete: PropTypes.func,

  onRequestSort: PropTypes.func,

  onSelectAllClick: PropTypes.func,

  order: PropTypes.string,

  orderBy: PropTypes.string,

  page: PropTypes.number,

  rows: PropTypes.array,

  rowsPerPage: PropTypes.number,

  selected: PropTypes.array,

  title: PropTypes.string
};

const useStyles = makeStyles(theme => ({
  root: props =>
    props.root
      ? props.root
      : {
          width: '100%',
          marginBottom: theme.spacing(2)
        },
  table: {
    position: 'relative'
  }
}));

const FileManagerTable = ({
  children,
  headCells,
  onFileUpload,
  onChangePage,
  onChangeRowsPerPage,
  onDelete,
  onLightBoxOpen,
  onRequestSort,
  onSelectAllClick,
  order,
  orderBy,
  page,
  rows,
  rowsPerPage,
  selected,
  title,
  ...props
}) => {
  const classes = useStyles(props.classes);
  return (
    <Paper className={classes.root}>
      <FileManagerTableToolbar
        onFileUpload={onFileUpload}
        numSelected={selected.length}
        onDelete={onDelete}
        onLightBoxOpen={onLightBoxOpen}
      >
        {title}
      </FileManagerTableToolbar>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <FileManagerTableHeader
            headCells={headCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={onSelectAllClick}
            onRequestSort={onRequestSort}
            rowCount={rows.length}
          />
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </Paper>
  );
};

FileManagerTable.propTypes = propTypes;
export default FileManagerTable;
