import axios from 'axios';
import { get } from 'lodash/fp';

export const PUT_ENTRY = 'PUT_ENTRY';
export const PUT_ENTRY_ERROR = 'PUT_ENTRY_ERROR';

export const putEntrySuccess = success => ({
  type: PUT_ENTRY,
  ...success
});

export const putEntryError = error => ({
  type: PUT_ENTRY_ERROR,
  ...error
});

export const putEntry = (user, payload, id) => dispatch => {
  const token = get('Aa', user);
  const config = { headers: { Authorization: `Bearer ${token}` } };

  axios
    .put(
      `https://us-central1-baby-book-app-7d00b.cloudfunctions.net/app/entries/${id}`,
      payload,
      config
    )
    .then(() => {
      dispatch(
        putEntrySuccess({
          loading: false,
          alertSeverity: 'success',
          open: true,
          message: 'Your entry was updated successfully!',
          user
        })
      );
    })
    .catch(err => {
      dispatch(
        putEntryError({
          loading: false,
          alertSeverity: 'error',
          open: true,
          message: err.message
        })
      );
    });
};
