import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  deleteAllEntries,
  deleteEntry,
  getEntries,
  removeStorage
} from '../../redux/state/app/actions';
import { get, isArray, isEmpty, isEqual, isNil } from 'lodash/fp';

import AppSnackbar from '../../components/AppSnackbar';
import { AuthContext } from '../../context/AuthProvider/AuthProvider';
import Checkbox from '@material-ui/core/Checkbox';
import EntiresTable from '../../components/EntriesTable';
import EntryPreview from '../../components/EntriesTable/EntryPreview';
import Loading from '../../components/Loading';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { compose } from 'redux';
import { handleEntryClick } from '../../components/FileManangerTable/utility';
import { makeStyles } from '@material-ui/core';
import { push } from 'connected-react-router';
import withWidth from '@material-ui/core/withWidth';

const propTypes = {
  auth: PropTypes.object,

  /** CSS styles generated by withStyles that be overridden or extended */
  classes: PropTypes.object,

  entries: PropTypes.object,

  history: PropTypes.object,

  router: PropTypes.object,

  ui: PropTypes.object,

  width: PropTypes.string
};

const useStyles = makeStyles(theme => ({
  hideAuthorTableRow: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  loaderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 35
  },
  root: props => props.root
}));

const Entries = ({ auth, entries, history, router, ui, width, ...props }) => {
  const classes = useStyles(props.classes);
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
  };

  const headCells =
    isEqual('sm', width) || isEqual('xs', width)
      ? [
          { id: 'title', numeric: false, disablePadding: true, label: 'Title' },
          { id: 'date', numeric: true, disablePadding: false, label: 'Date' },
          { id: 'age', numeric: true, disablePadding: false, label: 'Age' }
        ]
      : [
          { id: 'title', numeric: false, disablePadding: true, label: 'Title' },
          {
            id: 'author',
            numeric: true,
            disablePadding: false,
            label: 'Author'
          },
          { id: 'date', numeric: true, disablePadding: false, label: 'Date' },
          { id: 'age', numeric: true, disablePadding: false, label: 'Age' }
        ];

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [severity, setAlertSeverity] = useState('error');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('A message');
  const [entryIds, setEntryId] = useState([]);
  const [entryDeleted, setEntryDeleted] = useState(false);
  const [showPreview, setOpenpreview] = useState(false);
  const [selectedEntries, setEntry] = useState([]);

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';

      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy, setOrder, setOrderBy]
  );

  const handleSelectAllClick = useCallback(
    event => {
      if (event.target.checked) {
        const newSelecteds = rows.map(n => n.title);
        const newEntryIds = rows.map(n => n.id);

        setEntryId(newEntryIds);
        setSelected(newSelecteds);
        return;
      }
      setEntryId([]);
      setSelected([]);
    },
    [rows, setEntryId, setSelected]
  );

  const handleChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    event => {
      setRowsPerPage(parseInt(get('target.value', event), 10));
      setPage(0);
    },
    [setRowsPerPage, setPage]
  );

  const handleOnAdd = useCallback(() => {
    dispatch(push('/add-entry'));
  }, [dispatch]);

  const handleOnEdit = useCallback(() => {
    dispatch(push(`/edit-entry/${entryIds[0]}`));
  }, [dispatch, entryIds]);

  const handleDelete = useCallback(() => {
    setSelected([]);

    dispatch(deleteAllEntries(selectedEntries, user));

    selectedEntries.map(entry => {
      dispatch(deleteEntry(get('Aa', user), get('id', entry)));

      if (!isNil(entry.files)) {
        entry.files.map(file =>
          dispatch(
            removeStorage(get('file.storageId', file), get('file', file))
          )
        );
      }

      return setEntryDeleted(true);
    });
  }, [dispatch, selectedEntries, setEntryDeleted, setSelected, user]);

  const closeAlert = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const isSelected = title => selected.indexOf(title) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      !isEmpty(rows) ? rows.length - page : 0 * rowsPerPage
    );

  const formatDate = str => {
    const date = str.split('-');

    return `${date[1]}/${date[2]}/${date[0]}`;
  };

  const onEntryPreview = useCallback(() => {
    setOpenpreview(true);
  }, [setOpenpreview]);

  const closeEntryPreview = useCallback(() => {
    setOpenpreview(false);
  }, [setOpenpreview]);

  useEffect(() => {
    if (
      !isNil(user) &&
      !isEqual(false, user) &&
      isEmpty(get('location.state', router))
    ) {
      // console.log(user);
      dispatch(getEntries(get('Aa', user)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch]);

  useEffect(() => {
    if (!isEmpty(entries)) {
      setRows(get('entryList', entries));
    }

    if (get('response', entries) && isEqual(true, entryDeleted)) {
      setAlertSeverity(get('response.alertSeverity', entries));
      setOpen(get('response.open', entries));
      setMessage(get('response.message', entries));
    }

    if (get('location.state', router)) {
      setAlertSeverity(get('location.state.alertSeverity', router));
      setOpen(get('location.state.open', router));
      setMessage(get('location.state.message', router));

      history.replace();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries]);

  return isEmpty(rows) ||
    (!isEmpty(ui) && isEqual(true, get('showLoading', ui))) ? (
    <div className={classes.loaderContainer}>
      <Loading size={150} />
    </div>
  ) : (
    <React.Fragment>
      <AppSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={closeAlert}
        key={{ vertical: 'top', horizontal: 'center' }}
        message={message}
        open={open}
        severity={severity}
        variant="filled"
      />
      <EntiresTable
        className={classes.root}
        headCells={headCells}
        onAdd={handleOnAdd}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onEdit={handleOnEdit}
        onEntryPreview={onEntryPreview}
        onDelete={handleDelete}
        onSelectAllClick={handleSelectAllClick}
        onRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        page={page}
        rows={rows}
        rowsPerPage={rowsPerPage}
        selected={selected}
        title="Entires"
      >
        {!isNil(entries) &&
          !isNil(entries.entryList) &&
          isArray(entries.entryList) &&
          !isNil(rows) &&
          isArray(rows) &&
          stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row.title);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  id={row.id}
                  hover
                  onClick={event =>
                    handleEntryClick(
                      row.age,
                      row.author,
                      row.content,
                      formatDate(row.date),
                      entryIds,
                      event,
                      row.id,
                      row.files,
                      selected,
                      selectedEntries,
                      setEntry,
                      setEntryId,
                      setSelected,
                      row.title
                    )
                  }
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    {row.title}
                  </TableCell>
                  <TableCell
                    className={classes.hideAuthorTableRow}
                    align="right"
                  >
                    {row.author}
                  </TableCell>
                  <TableCell align="right">{formatDate(row.date)}</TableCell>
                  <TableCell align="right">
                    {isEqual('sm', width) || isEqual('xs', width)
                      ? row.age.replace('Months', 'm')
                      : row.age}
                  </TableCell>
                </TableRow>
              );
            })}
        {emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </EntiresTable>
      <EntryPreview
        entry={selectedEntries[0]}
        open={showPreview}
        onClose={closeEntryPreview}
      />
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    entries: state.entries,
    router: state.router,
    ui: state.ui
  };
}

Entries.propTypes = propTypes;
export default compose(withWidth(), connect(mapStateToProps))(Entries);
