import 'firebase/auth';

import firebase from 'firebase/app';

export const FORGOT_PASSWORD_SUBMIT = 'FORGOT_PASSOWORD_SUBMIT';
export const FORGOT_PASSWORD_SUBMIT_ERROR = 'FORGOT_PASSWORD_SUBMIT_ERROR';

export const forgotPasswordSubmitSuccess = success => ({
  type: FORGOT_PASSWORD_SUBMIT,
  ...success
});

export const forgotPasswordSubmitError = error => ({
  type: FORGOT_PASSWORD_SUBMIT_ERROR,
  ...error
});

export const forgotPasswordSubmit = values => dispatch => {
  firebase
    .auth()
    .sendPasswordResetEmail(values.forgotPasswordUserEmail)
    .then(() => {
      dispatch(
        forgotPasswordSubmitSuccess({
          alertSeverity: 'success',
          open: true,
          message: 'Password reset successful!',
          forgotPassword: false
        })
      );
    })
    .catch(err => {
      dispatch(
        forgotPasswordSubmitError({
          alertSeverity: 'error',
          open: true,
          message: err.message,
          code: err.code
        })
      );
    });
};
