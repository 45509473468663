import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';

const propTypes = {
  /** CSS styles generated by withStyles that be overridden or extended. */
  classes: PropTypes.object,

  /** The array of table cells to display. */
  headCells: PropTypes.array,

  /** Callback fired when the sort icon is clicked. */
  onRequestSort: PropTypes.func,

  /** Callback fired when select all is clicked. */
  onSelectAllClick: PropTypes.func,

  /** Sets the order of the items in the table column. */
  order: PropTypes.oneOf(['asc', 'desc']),

  /** The item to order sorting by in the table column. */
  orderBy: PropTypes.string,

  /** Number of items in the table selected. */
  numSelected: PropTypes.number,

  /** Number of table rows. */
  rowCount: PropTypes.number
};

const styles = () => ({
  root: {
    width: '100%'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
});

const AppTableHeader = ({
  classes,
  headCells,
  onSelectAllClick,
  onRequestSort,
  order,
  orderBy,
  numSelected,
  rowCount
}) => {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.root}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

AppTableHeader.propTypes = propTypes;

export default withStyles(styles)(AppTableHeader);
