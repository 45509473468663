import { GET_STORAGE, GET_STORAGE_ERROR } from './actions/getStorage';
import { LIST_STORAGE, LIST_STORAGE_ERROR } from './actions/listStorage';
import { PUT_STORAGE, PUT_STORAGE_ERROR } from './actions/putStorage';
import { REMOVE_STORAGE, REMOVE_STORAGE_ERROR } from './actions/removeStorage';

import getStorageErrorReducer from './reducers/getStorageErrorReducer';
import getStorageReducer from './reducers/getStorageReducer';
import listStorageErrorReducer from './reducers/listStorageErrorReducer';
import listStorageReducer from './reducers/listStorageReducer';
import putStorageErrorReducer from './reducers/putStorageErrorReducer';
import putStorageReducer from './reducers/putStorageReducer';
import removeStorageErrorReducer from './reducers/removeStorageErrorReducer';
import removeStorageReducer from './reducers/removeStorageReducer';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_STORAGE:
      return getStorageReducer(state, action);
    case GET_STORAGE_ERROR:
      return getStorageErrorReducer(state, action);
    case LIST_STORAGE:
      return listStorageReducer(state, action);
    case LIST_STORAGE_ERROR:
      return listStorageErrorReducer(state, action);
    case PUT_STORAGE:
      return putStorageReducer(state, action);
    case PUT_STORAGE_ERROR:
      return putStorageErrorReducer(state, action);
    case REMOVE_STORAGE:
      return removeStorageReducer(state, action);
    case REMOVE_STORAGE_ERROR:
      return removeStorageErrorReducer(state, action);
    default:
      return state;
  }
};
