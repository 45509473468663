import {
  FORGOT_PASSWORD_SUBMIT,
  FORGOT_PASSWORD_SUBMIT_ERROR
} from './actions/forgotPasswordSubmit';
import { SIGN_IN, SIGN_IN_ERROR, SIGN_OUT } from './actions/login';

import forgotPasswordSubmitReducer from './reducers/forgotPasswordSubmitReducer';
import loginReducer from './reducers/loginReducer';

const defaultState = {
  anchorEl: false,
  passwordReset: false,
  formSubmission: false,
  forgotPassword: null,
  alertSeverity: null,
  open: false,
  message: null,
  openModal: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_SUBMIT:
      return forgotPasswordSubmitReducer(state, action);
    case FORGOT_PASSWORD_SUBMIT_ERROR:
      return forgotPasswordSubmitReducer(state, action);
    case SIGN_IN:
      return loginReducer(state, action);
    case SIGN_IN_ERROR:
      return loginReducer(state, action);
    case SIGN_OUT:
      return loginReducer(state, action);
    default:
      return state;
  }
};
