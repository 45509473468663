import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const propTypes = {
  /** A HTML element, or a function that returns it. It's used to set the position of the menu. */
  anchorEl: PropTypes.any,

  /** The anchor of the menu. */
  anchorOrigin: PropTypes.object,

  /** Menu contents. */
  children: PropTypes.node,

  /** CSS styles generated by withStyles that be overridden or extended. */
  classes: PropTypes.object,

  /** The id of the menu. */
  id: PropTypes.string,

  /** Always keep the children in the DOM. This prop can be useful in SEO situation or when you want to maximize the responsiveness of the menu. */
  keepMounted: PropTypes.bool,

  /** Callback fired when the component requests to be closed. */
  onClose: PropTypes.func,

  /** If true, the menu is visible. */
  open: PropTypes.bool,

  /** Trnasform origin style. */
  transformOrigin: PropTypes.object
};

const styles = () => ({
  root: {}
});

const AppMenu = ({
  anchorEl,
  anchorOrigin,
  children,
  classes,
  id,
  keepMounted,
  transformOrigin,
  onClose,
  open
}) => (
  <Menu
    id={id}
    anchorEl={anchorEl}
    anchorOrigin={anchorOrigin}
    className={classes.root}
    keepMounted={keepMounted}
    transformOrigin={transformOrigin}
    open={open}
    onClose={onClose}
  >
    {children}
  </Menu>
);

AppMenu.propTypes = propTypes;

export default withStyles(styles)(AppMenu);
