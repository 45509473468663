import { filter, isEmpty, remove } from 'lodash/fp';

export const headCells = [
  { id: 'file', numeric: false, disablePadding: true, label: 'File' }
];

// eslint-disable-next-line no-shadow
export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

// eslint-disable-next-line no-shadow
export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    // eslint-disable-next-line no-shadow
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const handleRequestSort = (
  event,
  order,
  orderBy,
  property,
  setOrder,
  setOrderBy
) => {
  const isAsc = orderBy === property && order === 'asc';

  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};

export const handleChangePage = (event, newPage, setPage) => {
  setPage(newPage);
};

export const handleChangeRowsPerPage = (event, setPage, setRowsPerPage) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

export const handleSelectAllClick = (
  event,
  setSelected,
  setSelectedRows,
  rows
) => {
  if (event.target.checked) {
    const newSelecteds = rows.map(n => n.file);
    const allRows = rows.map(n => ({
      id: n.id,
      file: n.file,
      inStorage: n.inStorage
    }));

    setSelectedRows([...allRows]);
    setSelected(newSelecteds);
    return;
  }
  setSelectedRows([]);
  setSelected([]);
};

export const handleEntryClick = (
  age,
  author,
  content,
  date,
  entryIds,
  event,
  id,
  files,
  selected,
  selectedEntries,
  setEntry,
  setEntryId,
  setSelected,
  title
) => {
  let newSelected = [];
  const selectedIndex = selected.indexOf(title);
  const selectedEntry = {
    id,
    title,
    author,
    date,
    age,
    content,
    files
  };

  if (selectedIndex === -1) {
    setEntryId([...entryIds, id]);
    setEntry([...selectedEntries, selectedEntry]);
    newSelected = newSelected.concat(selected, title);
  } else if (selectedIndex === 0) {
    setEntryId(filter(entryId => entryId !== id, entryIds));
    setEntry(remove(selectedEntry, selectedEntries));
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    setEntryId(filter(entryId => entryId !== id, entryIds));
    setEntry(remove(selectedEntry, selectedEntries));
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    setEntryId(filter(entryId => entryId !== id, entryIds));
    setEntry(remove(selectedEntry, selectedEntries));
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }

  setSelected(newSelected);
};

export const handleClick = (
  event,
  id,
  file,
  inStorage,
  selected,
  selectedRows,
  setSelected,
  setSelectedRows
) => {
  const selectedIndex = selected.indexOf(file);
  let newSelected = [];
  const selectedRow = { id, file, inStorage };

  if (selectedIndex === -1) {
    setSelectedRows([...selectedRows, selectedRow]);
    newSelected = newSelected.concat(selected, file);
  } else if (selectedIndex === 0) {
    setSelectedRows(remove(selectedRow, selectedRows));
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    setSelectedRows(remove(selectedRow, selectedRows));
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    setSelectedRows(remove(selectedRow, selectedRows));
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }

  setSelected(newSelected);
};

export const handleImageDelete = (
  imageDelete,
  selectedRows,
  setImageLoading,
  setSelected,
  setSelectedRows
) => {
  selectedRows.map(row => {
    setImageLoading(true);

    return imageDelete(row);
  });
  setSelected([]);
  setSelectedRows([]);
};

export const isSelected = (selected, file) => selected.indexOf(file) !== -1;

export const emptyRows = (page, rows, rowsPerPage) =>
  rowsPerPage -
  Math.min(rowsPerPage, !isEmpty(rows) ? rows.length - page : 0 * rowsPerPage);
