import 'firebase/auth';

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import firebase from 'firebase/app';

const propTypes = {
  /** The components that will have access to the auth context */
  children: PropTypes.any
};

export const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(signedInUser => {
      if (signedInUser) {
        setUser(signedInUser);
      } else {
        setUser(false);
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = propTypes;

export default AuthProvider;
