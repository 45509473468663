import 'firebase/storage';

import firebase from 'firebase/app';

export const REMOVE_STORAGE = 'REMOVE_STORAGE';
export const REMOVE_STORAGE_ERROR = 'REMOVE_STORAGE_ERROR';

export const removeStorageSuccess = storage => ({
  type: REMOVE_STORAGE,
  ...storage
});

export const removeStorageError = error => ({
  type: REMOVE_STORAGE_ERROR,
  ...error
});

export const removeStorage = (key, file) => dispatch => {
  const storageRef = firebase.storage().ref(`${key}/${file.name}`);

  storageRef
    .delete()
    .then(() =>
      dispatch(
        removeStorageSuccess({
          loading: false,
          alertSeverity: 'success',
          open: true,
          message: 'Your file was deleted successfully!'
        })
      )
    )
    .catch(err =>
      dispatch(
        removeStorageError({
          loading: false,
          alertSeverity: 'error',
          open: true,
          message: err.message
        })
      )
    );
};
