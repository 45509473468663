import EntriesTableHeader from './EntriesTableHeader';
import EntriesTableToolbar from './EntriesTableToolbar';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles } from '@material-ui/core/styles';

const propTypes = {
  children: PropTypes.node,

  /** CSS styles generated by withStyles that be overridden or extended */
  classes: PropTypes.object,

  headCells: PropTypes.array,

  onAdd: PropTypes.func,

  onChangePage: PropTypes.func,

  onChangeRowsPerPage: PropTypes.func,

  onDelete: PropTypes.func,

  onEdit: PropTypes.func,

  onEntryPreview: PropTypes.func,

  onRequestSort: PropTypes.func,

  onSelectAllClick: PropTypes.func,

  order: PropTypes.string,

  orderBy: PropTypes.string,

  page: PropTypes.number,

  rows: PropTypes.array,

  rowsPerPage: PropTypes.number,

  selected: PropTypes.array,

  title: PropTypes.string
};

const styles = theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2)
  }
});

const EntriesTable = ({
  children,
  classes,
  headCells,
  onAdd,
  onChangePage,
  onChangeRowsPerPage,
  onDelete,
  onEdit,
  onEntryPreview,
  onRequestSort,
  onSelectAllClick,
  order,
  orderBy,
  page,
  rows,
  rowsPerPage,
  selected,
  title
}) => (
  <Paper className={classes.root}>
    <EntriesTableToolbar
      onAdd={onAdd}
      onEdit={onEdit}
      onEntryPreview={onEntryPreview}
      numSelected={selected.length}
      onDelete={onDelete}
    >
      {title}
    </EntriesTableToolbar>
    <TableContainer>
      <Table
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <EntriesTableHeader
          headCells={headCells}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={onSelectAllClick}
          onRequestSort={onRequestSort}
          rowCount={rows.length}
        />
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  </Paper>
);

EntriesTable.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(EntriesTable);
