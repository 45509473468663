import axios from 'axios';
import { get } from 'lodash/fp';

export const GET_ENTRIES = 'GET_ENTRIES';
export const GET_ENTRIES_ERROR = 'GET_ENTRIES_ERROR';

export const getEntriesSuccess = entries => ({
  type: GET_ENTRIES,
  entries
});

export const getEntriesError = error => ({
  type: GET_ENTRIES_ERROR,
  ...error
});

export const getEntries = token => dispatch => {
  const config = { headers: { Authorization: `Bearer ${token}` } };

  axios
    .get(
      'https://us-central1-baby-book-app-7d00b.cloudfunctions.net/app/entries',
      config
    )
    .then(response => {
      dispatch(getEntriesSuccess(get('data', response)));
    })
    .catch(err => {
      dispatch(
        getEntriesError({
          alertSeverity: 'error',
          open: true,
          message: err.message
        })
      );
    });
};
