import AppButton from '../../AppButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const propTypes = {
  /** CSS styles generated by withStyles that be overridden or extended */
  classes: PropTypes.object,

  entry: PropTypes.object,

  onClose: PropTypes.func,

  open: PropTypes.bool
};

const defaultProps = {
  entry: {
    age: '',
    author: '',
    content: '',
    date: '',
    id: '',
    title: ''
  }
};

const styles = theme => ({
  age: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  content: {
    marginTop: theme.spacing(2)
  },
  root: {}
});

const EntryPreview = ({ classes, entry, onClose, open }) => {
  return (
    <Dialog onClose={onClose} fullWidth maxWidth="md" open={open}>
      <MuiDialogTitle
        disableTypography
        className={classes.root}
        id="customized-dialog-title"
        onClose={onClose}
      >
        <Typography variant="h6">{entry.title}</Typography>
        {onClose ? (
          <AppButton
            ariaLabel="close"
            classes={{
              root: classes.closeButton
            }}
            onClick={onClose}
            mode="icon"
          >
            <CloseIcon />
          </AppButton>
        ) : null}
      </MuiDialogTitle>
      <MuiDialogContent dividers>
        <Grid container alignItems="center" justify="center">
          <Grid container item xs={12} sm={12}>
            <Typography variant="body1">
              <strong>Date:</strong> {entry.date}
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <div>
              <Typography variant="body1">
                <strong>Author:</strong> {entry.author}
              </Typography>
            </div>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <div className={classes.age}>
              <Typography variant="body1">
                <strong>Age:</strong> {entry.age}
              </Typography>
            </div>
          </Grid>

          <Grid className={classes.content} item xs={12} sm={12}>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: entry.content }}
            />
          </Grid>
        </Grid>
      </MuiDialogContent>
    </Dialog>
  );
};

EntryPreview.propTypes = propTypes;
EntryPreview.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(EntryPreview);
