import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { isEqual, isNil } from 'lodash/fp';

import AddEntry from '../AddEntry';
import { AuthContext } from '../../context/AuthProvider/AuthProvider';
import { ConnectedRouter } from 'connected-react-router';
import EditEntry from '../EditEntry';
import Entries from '../Entries';
import Login from '../Login';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

const propTypes = {
  /** Browser history object. */
  history: PropTypes.object,

  /** Redux context object. */
  reactReduxContext: PropTypes.object
};

const Router = ({ history, reactReduxContext }) => {
  const { user } = useContext(AuthContext);

  const routes =
    !isNil(user) && isEqual(false, user) ? (
      <React.Fragment>
        <Redirect to={{ pathname: '/login' }} />
        <Route exact path="/login">
          <Login />
        </Route>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Route exact path="/" component={Entries} />
        <Route exact path="/add-entry" component={AddEntry} />
        <Route exact path="/edit-entry/:id" component={EditEntry} />
        <Route exact path="/login" component={Login} />
      </React.Fragment>
    );

  return (
    <ConnectedRouter history={history} context={reactReduxContext}>
      <Switch>{routes}</Switch>
    </ConnectedRouter>
  );
};

Router.propTypes = propTypes;

export default Router;
