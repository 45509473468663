import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const propTypes = {
  accept: PropTypes.string,

  children: PropTypes.node,

  /** CSS styles generated by withStyles that be overridden or extended */
  classes: PropTypes.object,

  id: PropTypes.string,

  onChange: PropTypes.func,

  type: PropTypes.string
};

const styles = () => ({
  input: {
    display: 'none'
  },
  root: {}
});

const FileUpload = ({ accept, children, classes, id, onChange, type }) => (
  <div className={classes.root}>
    <input
      accept={accept}
      className={classes.input}
      id={id}
      onChange={onChange}
      type={type}
    />
    <label htmlFor={id}>{children}</label>
  </div>
);

FileUpload.propTypes = propTypes;

export default withStyles(styles)(FileUpload);
