export const selectOptions = [
  '1 Month',
  '1 1/2 Months',
  '2 Months',
  '2 1/2 Months',
  '3 Months',
  '3 1/2 Months',
  '4 Months',
  '4 1/2 Months',
  '5 Months',
  '5 1/2 Months',
  ' 6 Months',
  '6 1/2 Months',
  '7 Months',
  '7 1/2 Months',
  '8 Months',
  '8 1/2 Months',
  '9 Months',
  '9 1/2 Months',
  '10 Months',
  '10 1/2 Months',
  '11 Months',
  '11 1/2 Months',
  '1 Year',
  '1 Year 1 Month',
  '1 Year 1 1/2 Months',
  '1 Year 2 Months',
  '1 Year 3 Months',
  '1 Year 4 Months',
  '1 Year 5 Months',
  '1 Year 6 Months',
  '1 Year 7 Months',
  '1 Year 8 Months',
  '1 Year 9 Months',
  '1 Year 10 Months',
  '1 Year 11 Months',
  '2 Years'
];
