import AppButton from '../AppButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const propTypes = {
  /** Side from which the drawer will appear. */
  anchor: PropTypes.string,

  /** The contents of the drawer. */
  children: PropTypes.any,

  /** CSS styles generated by withStyles that be overridden or extended. */
  classes: PropTypes.object,

  /** Callback fired when the component requests to be closed. */
  close: PropTypes.func,

  /** If true, the drawer is open. */
  open: PropTypes.bool,

  /** If true, a header for the drawer will be displayed. */
  showHeader: PropTypes.bool,

  /** Theme object from withStyles. */
  theme: PropTypes.object,

  /** The variant to use. */
  variant: PropTypes.string
};

const styles = theme => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  drawerPaper: {
    width: drawerWidth
  },
  root: {}
});

const AppDrawer = ({
  anchor,
  children,
  classes,
  close,
  open,
  showHeader,
  theme,
  variant
}) => (
  <Drawer
    anchor={anchor}
    classes={{
      paper: classes.drawerPaper
    }}
    className={classes.root}
    open={open}
    variant={variant}
  >
    {showHeader && (
      <div className={classes.drawerHeader}>
        <AppButton mode="icon" onClick={close}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </AppButton>
      </div>
    )}
    {children}
  </Drawer>
);

AppDrawer.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(AppDrawer);
