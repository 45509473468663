import './firebase.config';

import { Provider, ReactReduxContext } from 'react-redux';

import App from './containers/App';
import AuthProvider from './context/AuthProvider/AuthProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import Router from './containers/Router';
import { ThemeProvider } from '@material-ui/core/styles';
import configureStore from './redux/store/configureStore';
import { createBrowserHistory } from 'history';
import { theme } from './theme';

const history = createBrowserHistory();
const store = configureStore(history);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <App>
          <Router history={history} reactReduxContext={ReactReduxContext} />
        </App>
      </AuthProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
