import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  /** The component to render as the child of Formik form. */
  component: PropTypes.object,

  edit: PropTypes.bool,

  editorFormFieldError: PropTypes.bool,

  /** The form values.  */
  formValues: PropTypes.object,

  /** Callback to cancel form. */
  onCancel: PropTypes.func,

  onEditorChange: PropTypes.func,

  onEditorMouseEnter: PropTypes.func,

  /** Callback when the forgot password button is cliked.  */
  onForgotPassword: PropTypes.func,

  /** Callback when the form is submitted. */
  onSubmit: PropTypes.func,

  /** Form validation schema. */
  validationSchema: PropTypes.object
};

const Form = ({
  component,
  edit,
  editorFormFieldError,
  formValues,
  onCancel,
  onEditorChange,
  onEditorMouseEnter,
  onForgotPassword,
  onSubmit,
  validationSchema
}) => {
  const Component = component;
  return (
    <Formik
      onSubmit={values => onSubmit(values)}
      initialValues={formValues}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {formProps => (
        <Component
          {...formProps}
          edit={edit}
          editorFormFieldError={editorFormFieldError}
          onCancel={onCancel}
          onEditorChange={onEditorChange}
          onEditorMouseEnter={onEditorMouseEnter}
          onForgotPassword={onForgotPassword}
        />
      )}
    </Formik>
  );
};

Form.propTypes = propTypes;

export default Form;
