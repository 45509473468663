import { isEmpty, isNil } from 'lodash/fp';

import AppButton from '../AppButton';
import { Editor } from '@tinymce/tinymce-react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { selectOptions } from './selectOptions';
import { withStyles } from '@material-ui/core/styles';

const propTypes = {
  /** CSS styles generated by withStyles that be overridden or extended */
  classes: PropTypes.object,

  edit: PropTypes.bool,

  editorFormFieldError: PropTypes.bool,

  /** Formik errors. */
  errors: PropTypes.object,

  /** Callback for handling the onBlur events for the form fields. */
  handleBlur: PropTypes.func,

  /** Callback for handling onChange events for the form fields. */
  handleChange: PropTypes.func,

  /** Callback for handling the form submission. */
  handleSubmit: PropTypes.func,

  /** If true, the form is in a valid state. */
  isValid: PropTypes.bool,

  onCancel: PropTypes.func,

  onEditorChange: PropTypes.func,

  onEditorMouseEnter: PropTypes.func,

  /** Object of form fields that have been visited. */
  touched: PropTypes.object,

  /** Object of form field values. */
  values: PropTypes.object
};

const styles = theme => ({
  cancel: {
    marginRight: 'auto',
    marginTop: 15
  },
  content: {
    marginBottom: 40
  },
  date: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 40
    }
  },
  editor: {
    width: '100%'
  },
  paper: {
    padding: 25
  },
  root: {},
  select: {
    marginLeft: 30,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  submit: {
    marginLeft: 'auto',
    marginTop: 15
  },
  title: {
    marginBottom: 30,
    width: '100%'
  }
});

const EntryForm = ({
  classes,
  edit,
  editorFormFieldError,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isValid,
  onCancel,
  onEditorChange,
  onEditorMouseEnter,
  touched,
  values: { title, date, content, age }
}) => (
  <form onSubmit={handleSubmit}>
    <Grid container alignItems="center" justify="center">
      <Grid item xs={12} sm={12}>
        <Paper className={classes.paper}>
          <Typography variant="h5" component="h1" gutterBottom>
            {edit ? 'Edit Entry' : 'Add Entry'}
          </Typography>

          <TextField
            className={classes.title}
            error={touched.title && Boolean(errors.title)}
            helperText={touched.title ? errors.title : ''}
            id="title"
            label="Entry Title"
            margin="normal"
            name="title"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="title"
            required
            type="text"
            value={title}
            variant="outlined"
          />

          <Grid container item xs={12} className={classes.content}>
            <Grid container item xs={12} sm={6}>
              <TextField
                className={classes.date}
                error={touched.date && Boolean(errors.date)}
                helperText={touched.date ? errors.date : ''}
                id="date"
                InputLabelProps={{
                  shrink: true
                }}
                label="Date"
                onChange={handleChange}
                onBlur={handleBlur}
                name="date"
                type="date"
                variant="outlined"
                value={date}
              />
            </Grid>
            <Grid container item xs={12} sm={6}>
              <TextField
                className={classes.select}
                error={touched.age && Boolean(errors.age)}
                helperText={touched.age ? errors.age : ''}
                id="age"
                label="Select"
                name="age"
                onChange={handleChange}
                onBlur={handleBlur}
                select
                value={age}
                variant="outlined"
              >
                {selectOptions.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <TextField
            className={classes.editor}
            defaultValue=""
            id="content"
            error={editorFormFieldError}
            helperText={editorFormFieldError ? 'This field is required' : ''}
            name="content"
            InputProps={{
              inputComponent: Editor,
              inputProps: {
                apiKey: '84yo7ww8kzxk03zb5iaryan9ynkeg7i7fnxdm0n0cyhrf3ze',
                init: {
                  automatic_uploads: true,
                  height: 350,
                  width: '100%',
                  image_title: true,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link',
                    'charmap print preview anchor help',
                    'searchreplace visualblocks code',
                    'insertdatetime media table paste wordcount'
                  ],
                  toolbar:
                    // eslint-disable-next-line no-multi-str
                    'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | image code | help',
                  mobile: {
                    toolbar_mode: 'floating'
                  },
                  setup: editor => {
                    editor.on('blur', () => {
                      if (
                        window.tinymce &&
                        isEmpty(window.tinymce.activeEditor.getContent())
                      ) {
                        onEditorMouseEnter();
                      }
                    });
                  }
                }
              },
              onChange: onEditorChange
            }}
            variant="outlined"
            value={content}
          />

          <Grid container item xs={12}>
            <Grid container item xs={6}>
              <AppButton
                classes={{ root: classes.cancel }}
                size="large"
                onClick={onCancel}
              >
                Cancel
              </AppButton>
            </Grid>
            <Grid container item xs={6}>
              <AppButton
                classes={{ root: classes.submit }}
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={
                  !isValid ||
                  editorFormFieldError ||
                  (window.tinymce &&
                    !isNil(window.tinymce.activeEditor) &&
                    isEmpty(window.tinymce.activeEditor.getContent()))
                }
              >
                Submit
              </AppButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </form>
);

EntryForm.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(EntryForm);
