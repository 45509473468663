import {
  DELETE_ALL_ENTRIES,
  DELETE_ENTRY,
  GET_ENTRIES,
  POST_ENTRY,
  getEntries,
  showLoading
} from '../../state/app/actions';
import { get, isEqual } from 'lodash/fp';
import { put, take, takeLatest } from 'redux-saga/effects';

import { push } from 'connected-react-router';

export function* getEntriesWatcher() {
  // eslint-disable-next-line no-use-before-define
  yield takeLatest([DELETE_ALL_ENTRIES], getEntriesAfterDeleteWorker);
  // eslint-disable-next-line no-use-before-define
  yield takeLatest([GET_ENTRIES], stopLoadingAfterGettingEntriesWorker);
  // eslint-disable-next-line no-use-before-define
  yield takeLatest([POST_ENTRY], getEntriesAfterPost);
}

/**
 * GET entries after DELETE. If a batch DELETE then GET entires will not fire until the last entry is deleted.
 * @param {Object} action
 */
export function* getEntriesAfterDeleteWorker(action) {
  let deleteCount = 0;

  yield put(showLoading(true));

  // eslint-disable-next-line no-constant-condition
  while (true) {
    // eslint-disable-next-line no-plusplus
    deleteCount++;

    yield take(DELETE_ENTRY);

    if (isEqual(deleteCount, action.entries.length)) {
      yield put(getEntries(get('Aa', action.user)));
    }
  }
}

/**
 * Stop showing the loading spinner after GET entries
 */
export function* stopLoadingAfterGettingEntriesWorker() {
  yield put(showLoading(false));
}

/**
 * GET entries after POST and redirect to root page of the app
 */
export function* getEntriesAfterPost(action) {
  const user = get('user', action);

  yield put(showLoading(true));
  yield put(getEntries(get('Aa', user)));
  yield put(
    push('/', {
      loading: false,
      alertSeverity: 'success',
      open: true,
      message: 'Your entry was added successfully!'
    })
  );
}
