import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withStyles } from '@material-ui/core';

const propTypes = {
  /** CSS styles generated by withStyles that be overridden or extended. */
  classes: PropTypes.object,

  dispatch: PropTypes.func,

  handleDrawerClose: PropTypes.func,

  /** Array of list items. */
  listConfig: PropTypes.array
};

const styles = () => ({
  root: {}
});

const AppList = ({ classes, dispatch, handleDrawerClose, listConfig }) => {
  const onClick = link => {
    dispatch(push(link));
    handleDrawerClose();
  };

  return (
    <List className={classes.root}>
      {listConfig.map(item => (
        <React.Fragment key={item.text}>
          <ListItem button onClick={() => onClick(item.link)}>
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText primary={item.text} />
          </ListItem>
          {item.showDivider && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};

AppList.propTypes = propTypes;

export default compose(withStyles(styles), connect(null))(AppList);
