import auth from './auth/reducers';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import entries from './entries/reducers';
import storage from './storage/reducers';
import ui from './ui/reducers';

export default history =>
  combineReducers({
    auth,
    entries,
    router: connectRouter(history),
    storage,
    ui
  });
