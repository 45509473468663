import { Add, List } from '@material-ui/icons';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { get, isEqual, isNil } from 'lodash/fp';

import AccountCircle from '@material-ui/icons/AccountCircle';
import AppButton from '../../components/AppButton';
import AppDrawer from '../../components/AppDrawer';
import AppHeaderBar from '../../components/AppHeaderBar';
import AppList from '../AppList';
import AppMenu from '../../components/AppMenu';
import { AuthContext } from '../../context/AuthProvider/AuthProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { logOut } from '../../redux/state/app/actions';
import { withStyles } from '@material-ui/core';

const drawerWidth = 240;

const propTypes = {
  /** Auth state from the Redux store. */
  auth: PropTypes.object,

  /** The contents of the component. */
  children: PropTypes.element,

  /** CSS styles generated by withStyles that be overridden or extended. */
  classes: PropTypes.object,

  /** A function of the Redux store used to trigger a state change. */
  dispatch: PropTypes.func
};

const styles = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(3)}px 0 ${theme.spacing(3)}px 0`
    }
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  spacer: { padding: theme.spacing(0, 1), ...theme.mixins.toolbar },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  root: {
    display: 'flex'
  }
});

const App = ({ children, classes, dispatch, auth }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const { user } = useContext(AuthContext);

  const listConfig = [
    {
      icon: <List />,
      link: '/',
      showDivider: true,
      text: 'Entries'
    },
    {
      icon: <Add />,
      link: '/add-entry',
      showDivider: true,
      text: 'Add Entry'
    }
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const userSignOut = useCallback(() => {
    dispatch(logOut());
  }, [dispatch]);

  useEffect(() => {
    if (!isNil(auth) && isEqual(null, get('anchorEl', auth))) {
      setAnchorEl(auth.anchorEl);
    }
  }, [auth]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppHeaderBar
        dispatch={dispatch}
        title="Baby Book App"
        isOpen={open}
        open={handleDrawerOpen}
        showMenu={!isNil(user) && !isEqual(false, user)}
      >
        {!isNil(user) && !isEqual(false, user) && (
          <div>
            <AppButton
              ariaLabel="account of current user"
              ariaControls="menu-appbar"
              ariaHasPopup="true"
              onClick={handleMenu}
              color="inherit"
              mode="icon"
            >
              <AccountCircle />
            </AppButton>
            <AppMenu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={menuOpen}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={userSignOut}>Sign Out</MenuItem>
            </AppMenu>
          </div>
        )}
      </AppHeaderBar>
      <AppDrawer
        anchor="left"
        classes={{
          root: classes.drawer
        }}
        close={handleDrawerClose}
        open={open}
        showHeader
        variant="persistent"
      >
        <Divider />
        <AppList
          listConfig={listConfig}
          handleDrawerClose={handleDrawerClose}
        />
      </AppDrawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.spacer} />
        {children}
      </main>
    </div>
  );
};

App.propTypes = propTypes;

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps)
)(App);
