import firebase from 'firebase/app';

// const firebaseConfigTest = {
//   apiKey: 'AIzaSyBljQqSa3J6xLGrPnWohEj3futgRdum2Hc',
//   authDomain: 'test-baby-book.firebaseapp.com',
//   databaseURL: 'https://test-baby-book-default-rtdb.firebaseio.com',
//   projectId: 'test-baby-book',
//   storageBucket: 'test-baby-book.appspot.com',
//   messagingSenderId: '1057166843388',
//   appId: '1:1057166843388:web:69543ffcade3b3bb792679'
// };

const firebaseConfigProd = {
  apiKey: 'AIzaSyBc2yfFy4EyywkI8l5SqNg7HpSJl9i2YGw',
  authDomain: 'baby-book-app-7d00b.firebaseapp.com',
  projectId: 'baby-book-app-7d00b',
  storageBucket: 'baby-book-app-7d00b.appspot.com',
  messagingSenderId: '85182466607',
  appId: '1:85182466607:web:4153bab55cef71133ad4da'
};

firebase.initializeApp(firebaseConfigProd);
