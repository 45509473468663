import { SHOW_LOADING } from './actions/showLoading';
import showLoadingReducer from './reducers/showLoadingReducer';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SHOW_LOADING:
      return showLoadingReducer(state, action);
    default:
      return state;
  }
};
