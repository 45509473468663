import React, { useContext } from 'react';
import { isEqual, isNil } from 'lodash/fp';

import AppBar from '@material-ui/core/AppBar';
import AppButton from '../AppButton';
import AppToolbar from '../AppToolbar';
import { AuthContext } from '../../context/AuthProvider/AuthProvider';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import { withStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const propTypes = {
  /** The content of the component. */
  children: PropTypes.any,

  /** CSS styles generated by withStyles that be overridden or extended. */
  classes: PropTypes.object,

  dispatch: PropTypes.func,

  /** If true, should shift AppBar to the right. */
  isOpen: PropTypes.bool,

  /** Callback fired when the menu icon is clicked. */
  open: PropTypes.func,

  /** Position to apply to the AppBar. */
  position: PropTypes.string,

  /** If true, will show the menu icon. */
  showMenu: PropTypes.bool,

  /** AppBar title. */
  title: PropTypes.string
};

const styles = theme => ({
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  hide: {
    display: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  root: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  title: {
    cursor: 'pointer',
    flexGrow: 1
  }
});

const AppHeaderBar = ({
  children,
  classes,
  dispatch,
  isOpen,
  open,
  position,
  showMenu,
  title
}) => {
  const { user } = useContext(AuthContext);

  const redirectHome = () => {
    dispatch(push('/'));
  };

  const redirectLogin = () => {
    dispatch(push('/login'));
  };

  return (
    <AppBar
      className={clsx(classes.root, {
        [classes.appBarShift]: isOpen
      })}
      position={position}
    >
      <AppToolbar>
        {showMenu && (
          <AppButton
            ariaLabel="open drawer"
            classes={{ root: clsx(classes.menuButton, isOpen && classes.hide) }}
            color="inherit"
            edge="start"
            mode="icon"
            onClick={open}
          >
            <MenuIcon />
          </AppButton>
        )}
        <Typography
          className={classes.title}
          variant="h6"
          noWrap
          onClick={
            !isNil(user) && !isEqual(false, user) ? redirectHome : redirectLogin
          }
        >
          {title}
        </Typography>
        {children}
      </AppToolbar>
    </AppBar>
  );
};

AppHeaderBar.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(AppHeaderBar);
