import AppButton from '../AppButton';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const propTypes = {
  /** CSS styles generated by withStyles that be overridden or extended */
  classes: PropTypes.object,

  /** Formik errors. */
  errors: PropTypes.object,

  /** Callback for handling the onBlur events for the form fields. */
  handleBlur: PropTypes.func,

  /** Callback for handling onChange events for the form fields. */
  handleChange: PropTypes.func,

  /** Callback for handling the form submission. */
  handleSubmit: PropTypes.func,

  /** If true, the form is in a valid state. */
  isValid: PropTypes.bool,

  /** Callback for showing the forgot password form. */
  onForgotPassword: PropTypes.func,

  /** Object of form fields that have been visited. */
  touched: PropTypes.object,

  /** Object of form field values. */
  values: PropTypes.object
};

const styles = theme => ({
  forgotPassword: {
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: '100%'
    }
  },
  paper: {
    padding: 25
  },
  root: {},
  submit: {
    marginLeft: 'auto',
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      textAlign: 'center',
      width: '100%'
    }
  },
  textField: {
    width: '100%'
  }
});

const LoginForm = ({
  classes,
  errors,
  handleSubmit,
  handleBlur,
  handleChange,
  isValid,
  onForgotPassword,
  touched,
  values: { userEmail, password }
}) => (
  <form onSubmit={handleSubmit}>
    <Grid container alignItems="center" justify="center">
      <Grid item xs={12} sm={7}>
        <Paper className={classes.paper}>
          <Typography variant="h4" component="h4">
            Sign In
          </Typography>

          <TextField
            className={classes.textField}
            error={touched.userEmail && Boolean(errors.userEmail)}
            helperText={touched.userEmail ? errors.userEmail : ''}
            id="userEmail"
            label="User Email"
            margin="normal"
            name="userEmail"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="user email"
            required
            type="text"
            value={userEmail}
            variant="outlined"
          />

          <TextField
            className={classes.textField}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password ? errors.password : ''}
            id="password"
            label="Password"
            margin="normal"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="password"
            required
            rows="6"
            type="password"
            value={password}
            variant="outlined"
          />
          <Grid container item xs={12}>
            <Grid container item xs={12} sm={6}>
              <AppButton
                classes={{ root: classes.forgotPassword }}
                onClick={onForgotPassword}
              >
                Forgot Password
              </AppButton>
            </Grid>

            <Grid container item xs={12} sm={6}>
              <AppButton
                classes={{ root: classes.submit }}
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={!isValid}
              >
                Submit
              </AppButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </form>
);

LoginForm.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(LoginForm);
