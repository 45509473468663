import { assign } from 'lodash/fp';

export default (state, action) =>
  assign(state, {
    alertSeverity: action.alertSeverity,
    open: action.open,
    message: action.message,
    forgotPassword: action.forgotPassword,
    code: action.code
  });
