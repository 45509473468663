import { applyMiddleware, compose, createStore } from 'redux';

import { createLogger } from 'redux-logger';
import createRootReducer from '../state/app/reducers';
import createSagaMiddleware from 'redux-saga';
import promise from 'redux-promise-middleware';
import { rootSaga } from '../sagas/sagas';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

const sagaMiddleware = createSagaMiddleware();

const configureStore = history => {
  const store = createStore(
    createRootReducer(history),
    compose(
      applyMiddleware(
        routerMiddleware(history),
        promise,
        thunk,
        sagaMiddleware,
        createLogger()
      )
    )
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
