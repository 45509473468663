import axios from 'axios';

export const DELETE_ENTRY = 'DELETE_ENTRY';
export const DELETE_ENTRY_ERROR = 'DELETE_ENTRY_ERROR';

export const deleteEntrySuccess = success => ({
  type: DELETE_ENTRY,
  ...success
});

export const deleteEntryError = error => ({
  type: DELETE_ENTRY_ERROR,
  ...error
});

export const deleteEntry = (token, id) => dispatch => {
  const config = { headers: { Authorization: `Bearer ${token}` } };

  axios
    .delete(
      `https://us-central1-baby-book-app-7d00b.cloudfunctions.net/app/entries/${id}`,
      config
    )
    .then(() => {
      dispatch(
        deleteEntrySuccess({
          loading: false,
          alertSeverity: 'success',
          open: true,
          message: 'Your entry was deleted successfully!'
        })
      );
    })
    .catch(err => {
      dispatch(
        deleteEntryError({
          loading: false,
          alertSeverity: 'error',
          open: true,
          message: err.message
        })
      );
    });
};
