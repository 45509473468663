import { Close, GetApp } from '@material-ui/icons';
import { get, isEqual } from 'lodash/fp';

import AppBar from '@material-ui/core/AppBar';
import AppButton from '../../AppButton';
import AppToolbar from '../../AppToolbar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core';

const propTypes = {
  /** CSS styles generated by withStyles that be overridden or extended */
  classes: PropTypes.object,

  file: PropTypes.object,

  onClose: PropTypes.func,

  open: PropTypes.bool
};

const useStyles = makeStyles({
  closeButton: {
    marginLeft: 'auto'
  },
  link: {
    color: 'inherit'
  },
  img: {
    width: '100%'
  },
  root: props => props.root
});

const FileLightBox = ({ file, onClose, open }, ...props) => {
  const classes = useStyles(props.classes);

  return (
    <Dialog
      className={classes.root}
      fullScreen
      maxWidth="lg"
      onClick={onClose}
      open={open}
    >
      <AppBar color="primary">
        <AppToolbar>
          <a
            className={classes.link}
            href={get('url', file)}
            download={get('url', file)}
          >
            <AppButton mode="icon" color="inherit">
              <GetApp />
            </AppButton>
          </a>
          <AppButton
            classes={{ root: classes.closeButton }}
            color="inherit"
            mode="icon"
            onClick={onClose}
          >
            <Close />
          </AppButton>
        </AppToolbar>
      </AppBar>

      <DialogContent>
        {isEqual(true, get('video', file)) ? (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video controls>
            <source src={get('url', file)} />
          </video>
        ) : (
          <img className={classes.img} src={get('url', file)} alt="lightbox" />
        )}
      </DialogContent>
    </Dialog>
  );
};

FileLightBox.propTypes = propTypes;

export default FileLightBox;
