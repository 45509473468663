import * as Yup from 'yup';

import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { forgotPasswordSubmit, login } from '../../redux/state/app/actions';
import { get, isNil } from 'lodash/fp';

import AppSnackbar from '../../components/AppSnackbar';
import ForgotPasswordForm from '../../components/ForgotPasswordForm';
import Form from '../../components/Form';
import Grid from '@material-ui/core/Grid';
import Loading from '../../components/Loading';
import LoginForm from '../../components/LoginForm';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const propTypes = {
  /** Auth state from the Redux store. */
  auth: PropTypes.object,

  /** CSS styles generated by makeStyles that be overridden or extended. */
  classes: PropTypes.object,

  /** Application history object  */
  history: PropTypes.object
};

const validationSchema = Yup.object({
  userEmail: Yup.string('Enter your user email').required(
    'User email is required'
  ),
  password: Yup.string('Enter your password').required('Password is required')
});

const forgotPasswordSchema = Yup.object({
  forgotPasswordUserEmail: Yup.string('Enter your user email').required(
    'User email is required'
  )
});

const useStyles = makeStyles({
  loaderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    padding: 35,
    textAlign: 'center'
  },
  root: props => props.root
});

const Login = ({ auth, history, ...props }) => {
  const [formSubmission, setFormSubmission] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('A message');
  const [forgotPassword, showForgotPassword] = useState(false);
  const [severity, setAlertSeverity] = useState('error');
  const dispatch = useDispatch();
  const classes = useStyles(props.classes);

  const formValues = { userName: '', password: '' };

  const forgotPasswordFormValues = {
    forgotPasswordUserEmail: ''
  };

  const handleSubmit = useCallback(
    values => {
      setFormSubmission(true);
      dispatch(login(values));
    },
    [dispatch]
  );

  const closeAlert = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onForgotPasswordCancel = useCallback(() => {
    showForgotPassword(false);
  }, [showForgotPassword]);

  const onForgotPassword = useCallback(() => {
    showForgotPassword(true);
  }, [showForgotPassword]);

  const onForgotPasswordSubmit = useCallback(
    values => {
      dispatch(forgotPasswordSubmit(values));
    },
    [dispatch]
  );

  useEffect(() => {
    if (auth && !isNil(get('alertSeverity', auth))) {
      setFormSubmission(auth.formSubmission);
      setAlertSeverity(auth.alertSeverity);
      setOpen(auth.open);
      setMessage(auth.message);
    }

    if (auth && !isNil(get('forgotPassword', auth))) {
      showForgotPassword(auth.forgotPassword);
    }
  }, [auth]);

  return formSubmission ? (
    <Grid container alignItems="center" justify="center">
      <Grid item xs={7}>
        <Paper className={classes.paper}>
          <Loading size={150} />
        </Paper>
      </Grid>
    </Grid>
  ) : (
    <div className={classes.root}>
      {!forgotPassword ? (
        <Form
          component={LoginForm}
          formValues={formValues}
          initialValues={formValues}
          onForgotPassword={onForgotPassword}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        />
      ) : (
        <Form
          component={ForgotPasswordForm}
          formValues={forgotPasswordFormValues}
          initialValues={forgotPasswordFormValues}
          onCancel={onForgotPasswordCancel}
          onSubmit={onForgotPasswordSubmit}
          validationSchema={forgotPasswordSchema}
        />
      )}
      <AppSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={closeAlert}
        key={{ vertical: 'top', horizontal: 'center' }}
        message={message}
        open={open}
        severity={severity}
        variant="filled"
      />
    </div>
  );
};

Login.propTypes = propTypes;

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps)(Login);
