import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

const propTypes = {
  /** The contents of the toolbar. */
  children: PropTypes.element,

  /** CSS styles generated by withStyles that be overridden or extended. */
  classes: PropTypes.object,

  /** The component used for the root node. Either a string to use a HTML element or a component. */
  component: PropTypes.elementType,

  /** The variant to use. */
  variant: PropTypes.string
};

const styles = () => ({
  root: {}
});

const AppToolbar = ({ children, classes, component, variant }) => (
  <Toolbar className={classes.root} component={component} variant={variant}>
    {children}
  </Toolbar>
);

AppToolbar.propTypes = propTypes;

export default withStyles(styles)(Toolbar);
