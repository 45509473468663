export const SHOW_LOADING = 'SHOW_LOADING';

export const showLoader = boolean => ({
  type: SHOW_LOADING,
  boolean
});

export const showLoading = boolean => dispatch => {
  dispatch(showLoader(boolean));
};
